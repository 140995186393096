
<script setup>
    import { ref, onMounted, onBeforeUnmount, watch } from "vue";
    import { getOrCreateDataObject, deleteDataObject, getOrCreateProcedure } from "o365.vue.ts";
    import { isMobile } from "mobile.utils.ts";
    import MBottomSheet from "mobile.vue.components.BottomSheet.vue";
    import MContainer from "mobile.vue.components.Container.vue";
    import MContent from "mobile.vue.components.Content.vue";
    import alert from "o365.controls.alert.ts";
    //import { alert } from ""

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean,
    });

    const dsTasks = getOrCreateDataObject({
        id: "dsTasks:" + crypto.randomUUID(),
        viewName: "atbv_LocalTest_SJA2",
        fields: [
            { name: "PrimKey" },
            { name: "ID" },
            { name: "Task" },
            { name: "Risk" },
            { name: "Action" },
            { name: "Checked", type: "Boolean" },
            { name: "Status" },
        ],
        "allowUpdate": true,
        "allowInsert": true,
        "allowDelete": true,
    });
    window.dsTasks = dsTasks;

    const procInsert = getOrCreateProcedure({ id: "procInsert:" + crypto.randomUUID(), procedureName: "astp_LocalTest_SJA2Insert" });

    const jsonData = ref(null);
    const showSJA = ref(false);

    const tempJson = `
{
  "Emne": "Sprukket stikkontaktdeksel ved taket",
  "Kategori": "Elektro",
  "Alvorlighetgrad": 3,
  "Utbedringsforslag": "Bytt ut det sprukne stikkontaktdekselet og inspiser kablene grundig.",
  "RisikoreduserendeTiltak": "Slå av strømmen før arbeidet påbegynnes. Bruk isolerte verktøy.",
  "SJA": true,
  "Avkrysningsliste": [
    "Slå av strømmen til stikkontakten.",
    "Fjern det sprukne dekselet forsiktig.",
    "Inspeksjon av kablene for eventuelle skader.",
    "Installer et nytt, intakt stikkontaktdeksel.",
    "Slå på strømmen og verifiser at alt fungerer som det skal."
  ],
  "SikkerJobbAnalyse": [
    {
      "SJA": true,
      "Arbeidsoppgaver": [
        {
          "Oppgave": "Slå av strømmen til stikkontakten",
          "Fare": ["Elektrisk støt"],
          "Tiltak": ["Bruk isolerte verktøy", "Sjekk spenningen med egnet måleverktøy"]
        },
        {
          "Oppgave": "Fjern det sprukne dekselet forsiktig",
          "Fare": ["Kuttskader"],
          "Tiltak": ["Bruk hansker", "Unngå å bruke skarpe gjenstander"]
        },
        {
          "Oppgave": "Inspeksjon av kablene for eventuelle skader",
          "Fare": ["Elektrisk støt", "Skade på kabler"],
          "Tiltak": ["Bruk isolerte verktøy", "Utfør en visuell inspeksjon av kablene"]
        },
        {
          "Oppgave": "Installer et nytt, intakt stikkontaktdeksel",
          "Fare": ["Elektrisk støt"],
          "Tiltak": ["Bruk isolerte verktøy", "Sjekk korrekt installasjon av dekselet"]
        },
        {
          "Oppgave": "Slå på strømmen og verifiser at alt fungerer som det skal",
          "Fare": ["Elektrisk støt", "Feilfunksjoner"],
          "Tiltak": ["Bruk isolerte verktøy", "Utfør funksjonstester før og etter påslag av strømmen"]
        }
      ]
    }
  ]
}
    `

    async function init() {
        /*
        // fetch raw json data
        const data = JSON.parse(tempJson);
        jsonData.value = data;
        console.log("data", data);

        // put json data into table (this should be done at some time upon creation, probably)
        const rows = data?.SikkerJobbAnalyse?.[0]?.Arbeidsoppgaver;
        if (rows) {
            for (let row of rows) {
                console.log("Inserting...", "row=", row);
                await procInsert.execute({
                    Step_ID: props.stepId,
                    Title: "test",
                    Task: row.Oppgave,
                    Risk: row.Fare.join(", "),
                    Action: row.Tiltak.join(", ")
                });
            }
        } else {
            console.warn("Unable to get SJA from json");
            alert($t("Unable to get SJA from json"));
        }
        */

        // fetch from table (dsTasks)

        dsTasks.recordSource.whereClause = `[Item_ID] = ${props.itemId}`
        await dsTasks.load();
        
    }

    function cycleStatus(row) {
        row.Status = (row.Status + 2)%3 - 1; // cycle between -1, 0, 1
        row.save();
    }

    function setStatus(row, status) {
        row.Status = status;
        row.save();
    }

    function getHeaderStyle(row) {
        if (row.Status !== -1) {
            return {
                backgroundColor: "var(--bs-gray-200)",
            }
        } else {
            return {};
        }
    }

    const addingTask = ref(false);
    const transitionName = ref("route-forward");

    const newTask = ref({});

    async function createTask() {
        console.log(newTask.value);
        const data = newTask.value;
        console.log("data", data);
        if (!data.Task) {
            alert($t("Task is missing"));
            return;
        }
        if (!data.Risk) {
            alert($t("Risk is missing"));
        }
        if (!data.Action) {
            alert($t("Action is missing"));
        }
        try {
            await procInsert.execute({
                Item_ID: props.itemId,
                Title: "Blank",
                Task: data.Task,
                Risk: data.Risk,
                Action: data.Action,
            });
            await dsTasks.load();
        } catch(err) {
            alert("Something went wrong " + String(err));
        }
        addingTask.value = false;
    }

    function cancelTask() {
        if (confirm($t("Are you sure you want to cancel adding task?"))) {
            addingTask.value = false;
        }
    }

    watch(addingTask, (val, old) => {
        if (val) {
            transitionName.value = "route-forward";
            newTask.value = {};
        } else {
            transitionName.value = "route-back";
        }
    });

    watch(showSJA, (val, old) => {
        if (!val) {
            addingTask.value = false;
        }
    });

    onMounted(() => {
        init();
    });

    onBeforeUnmount(() => {
        deleteDataObject(dsTasks);
    });
</script>

<template>
    <div>
        <div class="mb-1">
            <div class="font-2 fw-medium">{{ $t("SJA") }}</div>
            <div class="text-muted">SJA utføres før oppstart</div>
        </div>
        <template v-if="isMobile">
            <button class="w-100 btn btn-outline-primary dropdown-toggle" @click="showSJA = true">
                {{ $t("SJA") }}: {{ dsTasks.data.filter(e => e.Status !== -1).length }} / {{ dsTasks.data.length }} {{ $t("tasks checked") }}
            </button>
            <MBottomSheet v-model="showSJA" fullscreen>
                <!--
                <template #title>
                    {{ $t("SJA") }}
                </template>
                <template #action>
                    <a class="ms-2 p-2 d-block text-primary" style="text-decoration: none;" @click="addingTask = !addingTask">
                        {{ $t("Add task") }}
                    </a>
                </template>
                -->





        <template #title>
            <div class="d-flex justify-content-center align-items-center">
                <transition :name="transitionName">
                    <template v-if="addingTask">
                        <div style="position: absolute;">
                            {{ $t("Add task") }}
                        </div>
                    </template>
                    <template v-else>
                        <div style="position: absolute;">
                            {{ $t("SJA") }}
                        </div>
                    </template>
                </transition>
            </div>
        </template>
        <template #action>
            <transition name="fade">
                <template v-if="!addingTask">
                    <a class="d-flex align-items-center" style="position: absolute; top: 0; left: 0; bottom: 0; white-space: nowrap; text-decoration: none;" @click="addingTask = true">
                        <div class="ms-2 p-2">
                            {{ $t("Add task") }}
                        </div>
                    </a>
                </template>
                <template v-else>
                    <a class="d-flex justify-content-center align-items-center" style="height: 100%; aspect-ratio: 1; text-decoration: none;" @click="addingTask = false">
                        <i class="bi bi-chevron-left" />
                    </a>
                </template>
            </transition>
        </template>





                <template #body>
                    <transition :name="transitionName">
                        <template v-if="addingTask">
                        <MContainer>
                        <MContent>
                            <div class="p-3">
                                <div>
                                    <div class="font-n2 fw-medium">{{ $t("Task") }}</div>
                                    <input class="form-control" v-model="newTask.Task" />
                                </div>
                                <div class="mt-3">
                                    <div class="font-n2 fw-medium">{{ $t("Risk") }}</div>
                                    <input class="form-control" v-model="newTask.Risk" />
                                </div>
                                <div class="mt-3">
                                    <div class="font-n2 fw-medium">{{ $t("Action") }}</div>
                                    <input class="form-control" v-model="newTask.Action" />
                                </div>
                                <div class="mt-4">
                                    <div class="d-flex flex-column gap-2">
                                        <button class="flex-1 btn btn-outline-primary" @click="cancelTask()">
                                            {{ $t("Cancel") }}
                                        </button>
                                        <button class="flex-1 btn btn-primary" @click="createTask()">
                                            {{ $t("Add task") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </MContent>
                        </MContainer>
                        </template>
                        <template v-else>
                        <MContainer>
                        <MContent style="overflow-y: scroll;">
                        <div class="p-3">



                            <div class="accordion" id="accordion">
                            <template v-for="task in dsTasks.data">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" :style="getHeaderStyle(task)" type="button" data-bs-toggle="collapse" :data-bs-target="'#accordion' + task.PrimKey" aria-expanded="false" :aria-controls="'accordion' + task.PrimKey">
                                            <template v-if="false">
                                            <a class="d-block text-center flex-shrink-0 text-black" style="width: 3rem; cursor: pointer; margin-left: -0.5rem; margin-right: 0.5rem;">
                                                <template v-if="task.Status === 0">
                                                    <i class="bi bi-check" />
                                                </template>
                                                <template v-else-if="task.Status === 1">
                                                    <span class="font-n2">{{ $t("N/A") }}</span>
                                                </template>
                                                <template v-else-if="task.Status === -1">
                                                    <i class="bi bi-square" />
                                                </template>
                                            </a>
                                            </template>
                                            <template v-if="task.Status !== -1">
                                            <a class="d-block text-center flex-shrink-0 text-black" style="width: 1.5rem; cursor: pointer; margin-left: -0.5rem; margin-right: 0.5rem;">
                                                <template v-if="task.Status !== -1">
                                                    <i class="bi bi-check font-2" />
                                                </template>
                                            </a>
                                            </template>
                                            {{ task.Task }}
                                        </button>
                                    </h2>
                                    <div :id="'accordion' + task.PrimKey" class="accordion-collapse collapse" :data-bs-parent="'#accordion'" aria-labelledby="panelsStayOpen-headingOne">
                                        <div class="accordion-body">
                                            <div>
                                                <div class="font-n2 fw-medium">
                                                    {{ $t("Risk") }}
                                                </div>
                                                <div>
                                                    {{ task.Risk }}
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <div class="font-n2 fw-medium">
                                                    {{ $t("Action") }}
                                                </div>
                                                <div>
                                                    {{ task.Action }}
                                                </div>
                                            </div>
                                            <div class="mt-2 d-flex gap-2" style="margin-left: -0.25rem; margin-right: -0.25rem;">
                                                <a class="item-status item-status-not-checked" :class="{ active: (task.oldValues.Status ?? task.Status) === -1 }" @click="setStatus(task, -1)">
                                                    <div v-if="task.oldValues.Status !== undefined && task.Status === -1">
                                                        <div class="spinner-border spinner-border-sm" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        {{ $t("Not checked") }}
                                                    </div>
                                                </a>
                                                <a class="item-status item-status-na" :class="{ active: (task.oldValues.Status ?? task.Status) === 1 }" @click="setStatus(task, 1)">
                                                    <div v-if="task.oldValues.Status !== undefined && task.Status === 1">
                                                        <div class="spinner-border spinner-border-sm" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        {{ $t("N/A") }}
                                                    </div>
                                                </a>
                                                <a class="item-status item-status-checked" :class="{ active: (task.oldValues.Status ?? task.Status) === 0 }" @click="setStatus(task, 0)">
                                                    <div v-if="task.oldValues.Status !== undefined && task.Status === 0">
                                                        <div class="spinner-border spinner-border-sm" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        {{ $t("Checked") }}
                                                    </div>
                                                </a>
                                            </div>
                                            <!--
                                            <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                            -->
                                        </div>
                                    </div>
                                </div>
                            </template>
                            </div>



                        </div>
                        </MContent>
                        </MContainer>
                        </template>
                    </transition>
                </template>
            </MBottomSheet>
        </template>
        <template v-else>
            <ODataGrid :data-object="dsTasks" hideGridMenu hideMultiselectColumn>
                <OColumn field="Task" flexwidth="40" width="120" editable />
                <OColumn field="Risk" flexwidth="30" width="120" editable />
                <OColumn field="Action" flexwidth="30" width="120" editable />
                <OColumn field="Checked" width="100" #="{ row }">
                    <a class="d-block text-center text-black" style="cursor: pointer;" @click="cycleStatus(row)">
                        <template v-if="row.Status === 0">
                            <i class="bi bi-check-square" />
                        </template>
                        <template v-else-if="row.Status === 1">
                            {{ $t("N/A") }}
                        </template>
                        <template v-else-if="row.Status === -1">
                            <i class="bi bi-square" />
                        </template>
                    </a>
                </OColumn>
            </ODataGrid>
        </template>
    </div>
</template>

<style scoped>

    /* fade */
    .fade-enter-from {
        opacity: 0%;
    }
    .fade-enter-active {
        transition: all 400ms ease-in-out;
    }
    .fade-enter-to {
        opacity: 100%;
    }
    .fade-leave-from {
        opacity: 100%;
    }
    .fade-leave-active {
        transition: all 400ms ease-in-out;
    }
    .fade-leave-to {
        opacity: 0%;
    }


    a.item-status {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.75rem 0.25rem;

        text-decoration: none;

        border-top: 1px solid rgb(80%, 80%, 80%);

        &:not(.item-status-checked) {
            border-left: 1px solid rgb(80%, 80%, 80%);
        }

        transition: all 200ms ease-in-out;

        color: black;
        font-size: 0.9em;

        &.active {
            font-weight: 500;
            font-size: 0.9em;
        }

        &.item-status-not-checked {
            &.active {
                background-color: #ef4444;
                color: white;
            }
        }

        &.item-status-na {
            &.active {
                background-color: #292524;
                color: white;
            }
        }

        &.item-status-checked {
            &.active {
                background-color: #22c55e;
                /* background-color: var(--bs-success-bg-subtle); */
                color: white;
            }
        }

        &.item-status-not-checked {
            border-radius: 0 0 0 0.5rem;
        }

        &.item-status-checked {
            border-radius: 0 0 0.5rem 0;
        }

        border: 1px solid rgb(80%, 80%, 80%) !important;
        border-radius: 0.5rem !important;
    }

    /* forward */
    .route-forward-enter-from {
        transform: translate(100%, 0%);
        opacity: 0%;
    }
    .route-forward-enter-active {
        transition: all 300ms ease-in-out;
    }
    .route-forward-enter-to {
        transform: translate(0%, 0%);
    }
    .route-forward-leave-from {
        transform: translate(0%, 0%);
    }
    .route-forward-leave-active {
        transition: all 300ms ease-in-out;
    }
    .route-forward-leave-to {
        transform: translate(-100%, 0%);
        opacity: 0%;
    }

    /* back */
    .route-back-enter-from {
        transform: translate(-100%, 0%);
        opacity: 0%;
    }
    .route-back-enter-active {
        transition: all 300ms ease-in-out;
    }
    .route-back-enter-to {
        transform: translate(0%, 0%);
    }
    .route-back-leave-from {
        transform: translate(0%, 0%);
    }
    .route-back-leave-active {
        transition: all 300ms ease-in-out;
    }
    .route-back-leave-to {
        transform: translate(100%, 0%);
        opacity: 0%;
    }
</style>
